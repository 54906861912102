.svg-container {
  display: flex;
  align-items: center;
  gap: 16px; /* Spazio tra SVG e testo */
}

/* Stile per l'immagine SVG */
.svg-image {
  width: 10px; /* Larghezza dell'immagine SVG */
  height: auto; /* L'altezza si adatta automaticamente alla larghezza */
}

/* Stile del container del testo */
.text-container {
  display: flex;
  flex-direction: column; /* Allineamento verticale delle stringhe */
}

/* Stile per la prima stringa */
.stazPartenza {
  font-size: 14px;
}

/* Stile per la seconda stringa */
.stazArrivo {
  font-size: 14px;
}