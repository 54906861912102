.group {
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr);
  overflow-x: auto;
  column-gap: 1px;
}
  .calendar {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }  
  .month {
    padding: 5px;
    margin: 0px;
    width: min-content
  }
  .legendGroup {
    margin: 0px;
  }
  
  .monthTitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: normal;
  }
  
  .days {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    grid-auto-flow: column;
    gap: 2px;
    width: max-content;
  }

  .day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid #C2C2C2;
    border-radius: 3px;
    background-color: #C2C2C2;
    color: white;
    font-size: 9px;
    font-weight: bold;
    box-sizing: border-box;
  }
  .day-highlighted {
    border: 2px solid #DD5F5F !important;
  }
  .day-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: none;
  }
  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-size: 13px;
    border: none;
  }
  
  .day-header {
    border: none !important;
  }
  .spacer {
    width: 16px;
    flex-shrink: 0;
    border: none;
  }
  .L1Plus {
    grid-row: 1;
    grid-column: 8;
  }
  .L2Plus {
    grid-row: 2;
    grid-column: 8;
  }
  .L3Plus {
    grid-row: 3;
    grid-column: 8;
  }
  .L4Plus {
    grid-row: 4;
    grid-column: 8;
  }