.content {
    text-align: center;
}

.table{
    margin: 0;
}
.table th {
    padding: 5px;
}
.status-svg {
    margin-top: 10px;
}
.semaforo-svg{
    width: 25px !important;
}
.icona-svg{
    width: 25px !important;
    height: 25px !important;
}
.tableRTO {
    background-color: #f6f6f6;
    width: max-content;
    height: 72px;
    margin: 10px 0px 10px 0px;
    min-width: max-content;
  }
.svgImg{
    width: 440px;
    margin-right: 20px;
    /* margin-left: auto; */
}