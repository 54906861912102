.App {
    text-align: center;
}

/*
.EditRtoConfirm .x-panel-body{
    background-color: yellow !important;
}
.EditRtoConfirm .x-panelheader {
    background-color: yellow !important;
}
.EditRtoConfirm .x-paneltitle {
    color: #2572aa;
}*/

.x-innerhtml {
    width: 100%;
}

.EditRtoConfirm {
    color: #2572aa;
    background-color: yellow !important;
    border: 1px solid;
}

.backgroundWhite {
    background-color: white !important
}

.backgroundpeachpuff {
    background-color: peachpuff !important
}

.backgroundlightblue {
    background-color: lightblue !important
}

.backgroundScaduta {
    background-color: #d8d8d8 !important
}

.backgroundNonVisibile {
    background-color: #ffffff !important
}

.backgroundVisibile {
    background-color: #c6fcac !important
}

.LabelRiepilogoBlu {
    color: #2572aa !important;
}
.LabelRiepilogoRed {
    color: #A00000 !important;
}

/* Panel PO*/
.PnlHeader-PO {
    background-color: #A00000;
}

    .PnlHeader-PO .x-panelheader {
        background-color: #A00000;
    }

    .PnlHeader-PO .x-paneltitle {
        color: #FFFFFF;
    }

    .PnlHeader-PO .x-icon-el {
        color: #FFFFFF;
    }

/* Sottopanel di default (Liv1)*/
.PnlSectionL1 .x-panelheader {
    background-color: #ECECEC !important;
}

.PnlSectionL1 .x-paneltool .x-icon-el {
    color: #2572aa;
    opacity: 0.6;
}

.PnlSectionL1 .x-paneltitle {
    color: #2572aa;
}

.PnlSectionL1 .x-icon-el {
    color: #2572aa;
}

/* Sottopanel di default (Liv2)*/
.PnlSectionL2 .x-panelheader {
    background-color: #f5f5f5 !important;
}

.PnlSectionL2 .x-paneltool .x-icon-el {
    color: #2572aa;
    opacity: 0.6;
}

.PnlSectionL2 .x-paneltitle {
    color: #2572aa;
}

.PnlSectionL2 .x-icon-el {
    color: #2572aa;
}

.PnlSectionL2B {
    border: 1px solid #2572aa !important;
}

/* Sottopanel di PO (Liv1)*/
.PnlSectionPoL1 .x-panelheader {
    background-color: #ECECEC !important;
    margin: 8px 3px 2px 3px;
}

.PnlSectionPoL1 .x-paneltool .x-icon-el {
    color: #A00000;
    opacity: 0.6;
}

.PnlSectionPoL1 .x-paneltitle {
    color: #A00000;
}

.PnlSectionPoL1 .x-icon-el {
    color: #A00000;
}

/* Sottopanel di PO (Liv2)*/
.PnlSectionPOL2 .x-panelheader {
    background-color: #f5f5f5 !important;
}

.PnlSectionPOL2 .x-paneltool .x-icon-el {
    color: #A00000;
    opacity: 0.6;
}

.PnlSectionPOL2 .x-paneltitle {
    color: #A00000;
}

.PnlSectionPOL2 .x-icon-el {
    color: #A00000;
}

/* Sottopanel di PO (Traccia)*/
.PnlSectionPOTR .x-panelheader {
    background-color: #fcfcfc !important;
    margin: 4px 3px 2px 3px;
}

.PnlSectionPOTR .x-paneltool .x-icon-el {
    color: #A00000;
    opacity: 0.5;
}

.PnlSectionPOTR .x-icon-el {
    color: #A00000;
    opacity: 0.6;
}

.PnlSectionPOTR .x-paneltitle {
    color: #A00000;
}

.PnlSectionPOTR .x-text-el {
    color: #A00000;
    opacity: 0.6;
    font: bold 12px helvetica, arial, sans-serif !important;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    user-select: text !important;
}
.PnlSectionPOTR {
    border: 1px solid #A00000 !important;
    margin: 0px 0px 5px 0px;
}

/* Cliente Master Sottopanel (Liv1)*/
.PnlSectionCML1 .x-panelheader {
    background-color: #FFE4AC;
    margin: 8px 3px 2px 3px;
}

.PnlSectionCML1 .x-paneltool .x-icon-el {
    color: #2572aa;
    opacity: 0.6;
}

.PnlSectionCML1 .x-paneltitle {
    color: #2572aa;
}

.PnlSectionCML1 .x-icon-el {
    color: #2572aa;
}

/* Cliente Master Sottopanel (Liv2)*/
.PnlSectionCML2 .x-panelheader {
    background-color: #f5f5f5 !important;
    margin: 5px 2px 2px 8px;
}

.PnlSectionCML2 .x-paneltool .x-icon-el {
    color: #2572aa;
    opacity: 0.6;
}

.PnlSectionCML2 .x-paneltitle {
    background-color: #f5f5f5 !important;
    color: #2572aa;
}

.PnlSectionCML2 .x-icon-el {
    color: #2572aa;
}
.PnlCML {
    background-color: #FFE4AC !important;
}

/*
.App-link {
    color: #61dafb;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/


/* Button style*/
.ButtonAstro {
    background-color: #2572aa;
    margin: 20px 20px 20px 20px;
}

/*.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
}*/
.buttonSwitchOD {
    position: absolute;
    cursor: pointer;
    -webkit-transition: .4s;
    transition: .4s;
}

/* Button Menu ------------------------------------------------------------------------------------*/
.buttonMenu {
    background-color: #003780 !important;
    color: white !important;
}

.buttonGridTool {
    background-color: transparent !important;
    color: #157fcc !important;
    width: 24px !important;
    height: 24px !important;
}

.buttonGridToolPo {
    background-color: transparent !important;
    color: #A00000 !important;
    width: 24px !important;
    height: 24px !important;
}

.buttonEditPO {
    color: white !important;
    background-color: #A00000 !important;
    opacity: 0.9 !important;
    margin-left: 4px;
}
.buttonPanelTool {
    color: white !important;
    opacity: 0.9 !important;
    margin-left: 4px;
}
.exportExcelMiniGuide::before{
    font-size: 22px !important;
}

.buttonMerciTool {
    color: green !important;
    opacity: 0.9 !important;
    margin-left: 4px;
}
.buttonMerciTool:before {
        color: green !important;
        opacity: 0.9 !important;
        margin-left: 4px;
}
.buttonTool {
    background-color: transparent !important;
    position: absolute;
    color: #157fcc !important;
    font-size: 17px
}

.buttonToolDisabled {
    background-color: transparent !important;
    color: transparent !important;
    pointer-events: none !important;
    cursor: default;
}

/*----------TOGGLE---------------------------------------------------------------------------------------*/
.toggleOff {
    position: relative;
    cursor: pointer;
    height: 17px;
    width: 27px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #aaaaaa;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

    .toggleOff:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 12%;
        bottom: 20%;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 84px;
    }
.toggleOn {
    position: relative;
    cursor: pointer;
    height: 17px;
    width: 27px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2196F3;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

    .toggleOn:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 48%;
        bottom: 20%;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 84px;
    }


/* immagini ------------------------------------------------------------------------------------*/
.origine {
    background-image: url('images/up-full.svg') !important; /* Mantieni l'immagine */
    background-repeat: no-repeat; /* Evita la ripetizione */
    background-position: center; /* Centra l'immagine */
    background-size: contain; /* Scala l'immagine per adattarla senza distorsioni */
    background-color: transparent !important; /* Colore di sfondo trasparente */
}

.destino {
    background-image: url('images/down-full.svg') !important;
    background-repeat: no-repeat; /* Evita la ripetizione */
    background-position: center; /* Centra l'immagine */
    background-size: contain; /* Scala l'immagine per adattarla senza distorsioni */
    background-color: transparent !important; /* Colore di sfondo trasparente */
}

.transito {
    background-image: url('images/middle-empty.svg') !important;
    background-repeat: no-repeat; /* Evita la ripetizione */
    background-position: center; /* Centra l'immagine */
    background-size: contain; /* Scala l'immagine per adattarla senza distorsioni */
    background-color: transparent !important; /* Colore di sfondo trasparente */
}

.fermata {
    background-image: url('images/middle-full.svg') !important;
    background-repeat: no-repeat; /* Evita la ripetizione */
    background-position: center; /* Centra l'immagine */
    background-size: contain; /* Scala l'immagine per adattarla senza distorsioni */
    background-color: transparent !important; /* Colore di sfondo trasparente */
}

.originePO {
    background-image: url('images/up-fullPO.svg') !important;
    background-repeat: no-repeat; /* Evita la ripetizione */
    background-position: center; /* Centra l'immagine */
    background-size: contain; /* Scala l'immagine per adattarla senza distorsioni */
    background-color: transparent !important; /* Colore di sfondo trasparente */
}

.destinoPO {
    background-image: url('images/down-fullPO.svg') !important;
    background-repeat: no-repeat; /* Evita la ripetizione */
    background-position: center; /* Centra l'immagine */
    background-size: contain; /* Scala l'immagine per adattarla senza distorsioni */
    background-color: transparent !important; /* Colore di sfondo trasparente */
}

.transitoPO {
    background-image: url('images/middle-emptyPO.svg') !important;
    background-repeat: no-repeat; /* Evita la ripetizione */
    background-position: center; /* Centra l'immagine */
    background-size: contain; /* Scala l'immagine per adattarla senza distorsioni */
    background-color: transparent !important; /* Colore di sfondo trasparente */
}

.fermataPO {
    background-image: url('images/middle-fullPO.svg') !important;
    background-repeat: no-repeat; /* Evita la ripetizione */
    background-position: center; /* Centra l'immagine */
    background-size: contain; /* Scala l'immagine per adattarla senza distorsioni */
    background-color: transparent !important; /* Colore di sfondo trasparente */
}

.flag_italy {
    background-image: url('images/italy.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 24px 24px;
}

.flag_europe {
    background-image: url('images/europe.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 24px 24px;
}

.cer_Id1 {
    background-image: url('images/abiCli_Verde.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cer_Id2 {
    background-image: url('images/abiCli_Giallo.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cer_Id3 {
    background-image: url('images/abiCli_Rosso.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cer_Id4 {
    background-image: url('images/circle.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cer_Id5 {
    background-image: url('images/circle.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}


.cir_Id1 {
    background-image: url('images/abiCli_Verde.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cir_Id2 {
    background-image: url('images/abiCli_Giallo.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cir_Id3 {
    background-image: url('images/abiCli_Rosso.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cir_Id4 {
    background-image: url('images/circle.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cir_Id5 {
    background-image: url('images/circle.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cermp_Id1 {
    background-image: url('images/abiCli_Verde.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.cermp_Id2 {
    background-image: url('images/abiCli_Giallo.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.cermp_Id3 {
    background-image: url('images/abiCli_Rosso.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cermp_Id4 {
    background-image: url('images/circle.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.cermp_Id5 {
    background-image: url('images/circle.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}

.po-icon {
    margin: auto;
    width: 16px;
    cursor: pointer;
}

.warning {
    background-image: url('images/Warning.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 24px 24px;
}
.powarning {
    content: url('images/Warning.svg');
}

.warning-red {
    background-image: url('images/WarningRed.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 24px 24px;
}
.powarning-red {
    content: url('images/WarningRed.svg');
}

.check-tick {
    background-image: url('images/check-tick.png') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 15px 15px;
}

.iipres {
    background-image: url('images/ii_pres.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.poiipres {
    content: url('images/ii_pres.svg');
}

.iipresvb {
    background-image: url('images/ii_pres_vb.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.poiipresvb {
    content: url('images/ii_pres_vb.svg');
}

.iiok {
    background-image: url('images/ii_ok.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.poiiok {
    content: url('images/ii_ok.svg');    
}

.iiokvb {
    background-image: url('images/ii_ok_vb.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.poiiokvb {
    content: url('images/ii_ok_vb.svg');
}

.iipub {
    background-image: url('images/ii_pub.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.poiipub {
    content: url('images/ii_pub.svg');
}

.iipubvb {
    background-image: url('images/ii_pub_vb.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.poiipubvb {
    content: url('images/ii_pub_vb.svg');
}

.iiko {
    background-image: url('images/ii_ko.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.poiiko {
    content: url('images/ii_ko.svg');
}

.iikovb {
    background-image: url('images/ii_ko_vb.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent !important;
    background-size: 20px 20px;
}
.poiikovb {
    content: url('images/ii_ko_vb.svg');
}

/*-------------------------------------------------------------------------------------------------*/

/* Menucheckitem ------------------------------------------------------------------------------------*/

.checkContainerPadre .x-checkbox-icon-el {
    display: none !important;
}

.x-checkcell.x-checked .x-checkbox-el:before, .x-selected > * .x-checkcell.x-selection-cell .x-checkbox-el:before{
    font-size: 20px !important;
    color: #157fcc !important;
}


.checkContainerFiglio .x-checkbox-icon-el {
    color: black !important;
    color: red !important;
    border: 2px solid black !important;
    /* border: 2px solid #157fcc !important; */
    border-radius: 2px;
    box-sizing: content-box !important;
}
.x-checkcell .x-checkbox-el:not(.checkContainerFiglio){
    color: white !important;
    border: 2px solid #157fcc !important;
    border-radius: 2px;
    box-sizing: content-box !important;
}

.checkContainerFiglio .x-checkbox-icon-el:before{
    font-size: 20px !important;
    color: white !important;
    background-color: black !important;
}
.x-checkcolumn.x-checked .x-checkbox-el.x-font-icon::before:not(.checkContainerFiglio) {
    font-size: 20px !important;
    color: #003780 !important; 
}
.x-checkcolumn .x-checkbox-el:not(.checkContainerFiglio) {
    color: white;
    border: 3px solid #003780 !important;
    border-radius: 2px;
    box-sizing: content-box !important;
}
.x-checkcolumn.x-checked .x-checkbox-el:not(.checkContainerFiglio){
    color: #003780 !important; 
}
.x-checkcolumn .x-checkbox-el:before {
    font-size: 20px !important;
}

/*-------------------------------------------------------------------------------------------------*/

/* Custom ------------------------------------------------------------------------------------*/
.button-height {
    height: 30px !important;
    min-width: 30px !important;
}

.button-heightRTO {
    height: 24px !important;
}

.button-vertical-align {
    display: flex;
    align-items: center;
}

.hamburger-menu-button > .x-inner-el {
    background-color: #003780 !important;
    border-color: #003780 !important;
    background-image: none !important;
    border-radius: 0px !important;
    padding-right: 15px;
}

.blurtooriginaria-menu-button > .x-inner-el {
    background-color: #2572AA !important;
    border-color: #2572AA !important;
    background-image: none !important;
    border-radius: 0px !important;
    color: white !important;
}

.rossortooriginaria-menu-button > .x-inner-el {
    background-color: #A00000 !important;
    border-color: #A00000 !important;
    background-image: none !important;
    border-radius: 0px !important;
    color: white !important;
}

.logout-button {
    margin-right: 10px;
    color: white !important;
}

    .logout-button > .x-inner-el {
        background-color: #003780 !important;
        border-color: #003780 !important;
        background-image: none !important;
        border-radius: 0px !important;
    }

    .logout-button .x-icon-el {
        color: white !important;
    }

.title-icon {
    margin-right: 5px !important;
}


#title-text {
    font-size: 13px !important;
    background-color: transparent !important;
    border-color: transparent !important;
    background-image: none !important;
    color: black;
    font-weight:200;
}
.title-text {
    font-size: 16px !important;
    background-color: #003780 !important;
    border-color: #003780 !important;
    background-image: none !important;
}
#title-panel {
    font-size: 15px !important;
    background-color: transparent !important;
    font-weight: bold !important;
    color: #5c5c5c;
}
.title-panel {
    font-size: 13px !important;
    background-color: transparent !important;
    font-weight: bold !important;
    color: #5c5c5c;
}

    .title-text .x-innerhtml {
        font-size: 16px !important;
    }

.marginButton {
    margin-right: 10px;
}

    .marginButton .x-inner-el {
        border-radius: 3px !important;
        height: 30px !important;
    }

.endButton .x-inner-el {
    border-radius: 3px !important;
    height: 30px !important;
}

.actionButtonPanel > .x-dock-horizontal {
    align-items: center !important;
}

.ricercaAvanzataButtons > .x-body-el {
    justify-content: space-between !important;
}

.ricercaAvanzataButtons {
    margin: 5px 13px 0px 13px !important;
}

.ricercaAvanzataButton {
    color: #606060 !important;
}

    .ricercaAvanzataButton > .x-inner-el {
        background-color: transparent !important;
        border-color: transparent !important;
        background-image: none !important;
    }

.appButton > .x-inner-el {
    background-color: #157FCC !important;
    border-color: #157FCC !important;
    background-image: none !important;
}


.chartsButton > .x-inner-el {
    background-color: #51AD40 !important;
    border-color: #51AD40 !important;
    background-image: none !important;
    color: #fff;
}

.chartsButton .x-font-icon {
    color: #FFFFFF !important
}

.logoButton > .x-inner-el {
    background-image: url('images/rne-logo.svg') !important;
    border: none;
    outline: none;
}

.appButton .x-font-icon {
    color: #FFFFFF !important
}

.appButton .x-text-el {
    color: #FFFFFF !important
}

.appButtonDanger .x-text-el {
    color: #FFFFFF !important
}

.appButtonDanger > .x-inner-el {
    background-color: #AD4040 !important;
    border-color: #AD4040 !important;
    background-image: none !important;
}

.appButtonNeutro .x-text-el {
    color: #FFFFFF !important
}

.appButtonNeutro > .x-inner-el {
    background-color: #808080 !important;
    border-color: #808080 !important;
    background-image: none !important;
}

.exportButton > .x-inner-el {
    background-color: #51AD40 !important;
    border-color: #51AD40 !important;
    background-image: none !important;
}
.exportButton .x-font-icon {
    color: #FFFFFF !important
}
.ricercaAvanzataButton .x-font-icon {
    color: #157FCC !important
}
.caricaPdfButton .x-font-icon {
    color: #FFFFFF !important
}
.no-border .x-body-el {
    border: none !important;
}

.margin-5 {
    margin: 5px 13px !important;
}

.custom-title .x-title {
    display: flex !important;
    font-size: 14px !important;
    font-weight: bold !important;
}



.left-menu .x-treelist-item-text {
    line-height: 35px !important;
    font-size: 14px !important;
    padding-top: 5px;
}

.left-menu .x-treelist-item-icon {
    font-size: 14px !important;

}
     

.textarea-filtri .x-body-wrap-el {
    height: 22px !important;
}

.buttonInvertiLocalita .x-inner-el {
    padding-left: 2px !important;
    padding-right: 2px !important;
    height: 30px !important;
    margin-top: 18px !important;
    background: transparent !important;
    border: none !important;
}

.buttonInvertiLocalita .x-font-icon {
    color: #003780 !important;
}

.element-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* Definisce 6 colonne con larghezza uguale */
    gap: 10px;
    /* Spazio tra le celle */
}

.filterContainer .x-body-el {
    display: flex !important;
    flex-wrap: wrap !important;
}

.trainIcon .fa-train {
    width: 25px !important;
    height: 25px !important;
}

    .trainIcon .fa-train:before {
        font-size: 25px !important;
    }

.lottoToolbar > * {
    align-items: center !important;
}

.userInfo {
    display: flex;
    height: 100%;
}

.userInfo .x-inner-el {
    padding-right: 4px !important;
}

.userIcon {
    pointer-events: none !important;
    opacity: 1 !important;
    cursor: default !important;
}

    .userIcon > .x-inner-el {
        background-color: transparent !important;
        border-color: transparent !important;
        background-image: none !important;
    }

    .userIcon .x-body-el {
        display: flex;
        flex-direction: row-reverse;
        color: white !important;
    }

    .userIcon .fa-user {
        width: 25px !important;
        height: 25px !important;
    }

        .userIcon .fa-user:before {
            font-size: 25px !important;
            color: white !important;
        }

.text-container {
    margin-right: 5px;
    display: flex;
    flex-direction: column;
}

.nomeUtente {
    font-size: 12px;
    text-align: right !important;
    font-weight: lighter;
}

.ruoloUtente {
    font-size: 12px;
    text-align: right !important;
}

.userButtonsContainer .x-body-el {
    justify-content: space-between !important;
}
.userButtonsContainer {
    height: 36px;
}

.nomefiltro-bold-text .x-input-el {
    font-weight: bold;
}
.nomefiltro-bold-text > .x-body-wrap-el {
    width: 360px !important;
}
.marginBanner {
    margin-right: -1px;
}
/*Calendario-------------------------------------------------------------------------------------------------*/
.calendar-container {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

    .calendar-container .x-body-el {
        display: flex !important;
        align-items: center !important;
        justify-content: flex-end;
    }

.periodicitaHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    min-width: 550px;
    max-width: 1000px;
}

.plus-minus-button {
    width: 24px !important;
    height: 24px !important;
}

    .plus-minus-button .x-inner-el {
        padding-top: 0px !important;
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        border-top-width: 0px !important;
        border-left-width: 0px !important;
        border-right-width: 0px !important;
        border-bottom-width: 0px !important;
    }

.titleItemForm {
    transform: none !important;
}
.labelTransparent {
    color: transparent !important;
}
.x-field.x-disabled > .x-body-wrap-el {
    opacity: 0.7;
}

.x-field.x-disabled > .x-label-el {
    opacity: 0.7;
}

.iconTrainRTO .fa-train {
    width: 20px !important;
    height: 20px !important;
}

.iconTrainRTO .fa-train:before {
    font-size: 20px !important;
    color: white !important;
}

.iconTrainRTO .x-text-el {
    margin-left: 5px;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    user-select: text !important;
}
.iconTrainRTO .x-root, div.iconTrainRTO {
    /*-ms-user-select: text !important; */ /* Per Internet Explorer/Edge */
    user-select: text !important; /* Per i browser moderni */
    -webkit-user-select: text !important; /* Per Safari*/
    -moz-user-select: text !important; /*  Per Firefox */
}

.iconPanelsRTO .fa-th-list,
.iconPanelsRTO .fa-road,
.iconPanelsRTO .fa-calendar,
.iconPanelsRTO .fa-tachometer-alt,
.iconPanelsRTO .fa-train-tram,
.iconPanelsRTO .fa-link,
.iconPanelsRTO .fa-hand-paper,
.iconPanelsRTO .fa-clipboard-list,
.iconPanelsRTO .x-font-icon,
.iconPanelsRTO .fa-share-alt {
    width: 25px !important;
    height: 20px !important;
}

    .iconPanelsRTO .fa-th-list:before,
    .iconPanelsRTO .fa-road:before,
    .iconPanelsRTO .fa-calendar:before,
    .iconPanelsRTO .fa-tachometer-alt:before,
    .iconPanelsRTO .x-paneltitle .x-icon-el:before,
    .iconPanelsRTO .fa-share-alt:before {
        font-size: 20px !important;
    }

.iconPanelsRTO .x-text-el {
    margin-left: 5px;
    font-size: 16px !important;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    user-select: text !important;
}

/*------------------SPECIAL CLASS---------------------*/
.iconPanelsRTOSpecial .x-text-el:not(:first-child) {
    margin-left: 5px;
    font-size: 16px !important;
}
/*-------PLACEHOLDER*/
input[type=text]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder {
    color: white;
    -webkit-text-fill-color: white;
}
/*----------------------------------------------------*/

.column-in-panel .fa-th-list:before,
.column-in-panel .fa-road:before,
.column-in-panel .fa-calendar:before,
.column-in-panel .fa-tachometer-alt:before,
.column-in-panel .x-paneltitle .x-icon-el:before,
.column-in-panel .fa-share-alt:before {
    font-size: 13px !important;
    color: #157FCC;
}

.column-in-panel .x-text-el {
    font-size: 13px !important;
    margin-left: 0px !important;
}


.blueIconGrid .x-text-el {
    color: #157FCC;
}

.redIconGrid .x-text-el {
    color: #AD4040;
}

.blueIconGrid span {
    color: #157FCC;
}

.redIconGrid span {
    color: #AD4040;
}

.legendTable {
    margin: 10px auto 10px auto;
}

    .legendTable .blueIconGrid :before,
    .legendTable .redIconGrid :before {
        font-size: 20px !important;
    }

.legendText {
    padding: 2px 10px 2px 10px;
}

.legendCalendarText {
    padding: 0 15px 0 0;
    font-weight: normal !important;
}

.gridCambioStato {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row;
    align-items: center;
}

.cStatoTitle {
    margin-bottom: 10px;
    margin-top: 15px
}

.cStatoItem {
    margin-right: 10px;
}
.xPassaggioStato {
    color: red;
}
.checkPassaggioStato {
    color: #007bff;
}
/* Colore verde per ideStoAtaIpr 'A' */
.fa-circle.green {
    color: #339944;
  }
  
  /* Colore rosso per ideStoAtaIpr 'R' */
  .fa-circle.red {
    color: #d93226;
  }
  
  /* Colore giallo per ideStoAtaIpr 'P' */
  .fa-circle.yellow {
    color: #ffaa1f;
  }
  
  /* Colore blu per ideStoAtaIpr 'U' */
  .fa-circle.blue {
    color: #1D44BB ;
  }
  .fa-circle-check.blue {
    color: #1D44BB ;
  }
  .grey {
    color: #969696 ;
  }
/* --------------------------------------------- */
.custom-spinner {
    /* Aggiungi stili personalizzati per il tuo spinner */
    color: #007bff;
    /* Colore dell'icona */
    font-size: 24px;
    /* Dimensione dell'icona */
}

/* Stile per il container padre */
.container-spinner {
    position: relative;
}

    .container-spinner .custom-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

.custom-grid .x-mask-inner {
    background: transparent !important;
}

.custom-grid .x-loading-spinner-outer {
    display: none !important;
}
.column-in-panel .x-loading-spinner-outer {
    display: none !important;
}

    .spinMessage {
    font-size: 16px !important;
    white-space: nowrap !important;
}
.column-title{
    font-weight:lighter;
}
/*-------ROW EXPANDER-----------*/
.rowexpander {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: auto;
    margin-left: -3em;
    direction: ltr;
    width: calc(100vw - 50px);
    max-height:30rem;
}
#singlecolumn {
    column-count: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    direction: ltr;
    width: 100%;
    height: 100%;
    user-select: text;
}
#multicolumn {
    margin-left: 1em;
    column-count: 4;
    overflow-y: hidden;
    overflow-x: hidden;
    direction: ltr;
    width: 100%;
    height: 100%;
    user-select: text;
}

/*-------------------------------*/
.breadcrumb {
    background-color: #003780;
    display: inline-flex;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    color: white;
    font-size: 12px;
}
/*.unselectable {
    -webkit-user-select: none;*/ /* Safari */
    /*-moz-user-select: none;
    user-select: none;*/ /* Standard syntax */
/*}*/

.selectable {
    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text !important;
}

    .mc-icons-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.mc-icons {
    width: 16px;
    margin-right: 4px;
    cursor: pointer;
}

.fullWith {
    width: 100%;
}
/*-------------TOTAL SUMMARY ROW--------------- */
.rowSummary {
    color: black;
    background-color: #BBD7F2;
    font-weight: bold;
}
.rowNormal {
    color: black;
    background-color: #DCEAF7;
    text-align: center;
}
.x-gridcell{
text-align:start;

}
.noLine {
    cursor: default;
    text-decoration: none;
    pointer-events: none;
}

.line {
    pointer-events: stroke !important;
    text-decoration: underline;
    -webkit-text-stroke-width: 30px;
    -webkit-text-stroke-color: transparent;
}

.lineText {
    position: absolute;
}


/*----------------------------------------*/
.breadcrumbs-button {
    background: none;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;
    color: white;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

.breadcrumbs-sep {
    color: white;
    vertical-align: middle;
    padding: 0 5px 0 5px;
}

.userInfo .x-inner-el {
    padding-right: 4px !important;
}

.custom-button-notification {
    margin-right: 10px;
    color: white !important;
}

.custom-button-notification > .x-inner-el {
    background-color: #003780 !important;
    border-color: #003780 !important;
    background-image: none !important;
    padding: 0px !important;
}

.custom-button-notification .x-icon-el {
    color: white !important;
    margin-right: 5px !important;
}

.custom-button-notification .x-body-el,
.custom-button-notification .x-button-el {
    height: 100%;
}

.custom-button-notification .x-text-el {
    font-size: 14px;
    position: relative;
    top: 8px;
    right: 8px;
    padding: 0px 2px !important;
    border-radius: 3px;
    color: white !important;
}

.custom-red-button-notification .x-text-el {
    background: #ff0000;
}

.custom-blue-button-notification .x-text-el {
    background: #5fa2dd;
}

.custom-green-button-notification .x-text-el {
    background: #0fc900;
}

.custom-yellow-button-notification .x-text-el {
    background: #fadb14;
}

.labelNotifiche {
    font-weight: bold;
    /*margin-top: 8px;*/
    /*width: 236px;*/
}

.custom-home-button-notification {
    width: 90px;
    color: black !important;
}

.custom-home-button-notification > .x-inner-el {
    background-color: transparent !important;
    border-color: transparent !important;
    background-image: none !important;
    padding: 0px !important;
}

.custom-home-button-notification .x-icon-el {
    margin-right: 5px !important;
    width: max-content !important;
    height: max-content !important;
    justify-self: end
}
.custom-home-button-notification .x-icon-el:before {
    font-size: 24px !important;
}
.custom-home-button-notification> .x-inner-el> .x-body-el {
    display: grid;
    grid-template-columns: 55px 60px;

}
.custom-home-button-notification .x-body-el,
.custom-home-button-notification .x-button-el {
    height: 100%;

}
.custom-home-button-notification .x-text-el {
    /* font-size: 14px; */

    padding: 0px 2px !important;
    border-radius: 3px;
    color: #ff0000 !important;
}


.custom-home-red-button-notification .x-icon-el {
    color: #ff0000 !important;
}

.custom-home-blue-button-notification .x-icon-el {
    color: #5fa2dd !important;
}

.custom-home-green-button-notification .x-icon-el {
    color: #0fc900 !important;
}

.custom-home-yellow-button-notification .x-icon-el {
    color: #fadb14 !important;
}
.flex-container-nowrap> .x-body-el {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto; 
    margin: auto;
}
.documentsBody .x-body-el{
    overflow-y: auto;
}
.guideContainer {
    margin: 50px auto !important;
    min-width: 330px;
    max-width: 1570px;
    width: fit-content;
    overflow-x: auto;
    overflow-y: auto;
}
/* .guideContainer .x-container-body-el:not(.tipoGuida, .tipoGuida *) {
    overflow: auto;
    overflow-x: auto;
    overflow-y: auto;
} */
.guideContainer .x-text-el:not(.guidaButton, .guidaButton *) {
    font-size: 18px;
    font-weight: bold;
}
.tipoGuida {
    margin: 5px;
    max-width: 310px;
}
.tipoGuida .x-panelheader {
   align-items: center;
}
.tipoGuida .x-text-el:not(.guidaButton, .guidaButton *) {
    font-size: 16px;
    font-weight: bold;
}
.guidaButton {
    margin: 5px 0px !important;
    width: 300px
}
.guidaButton > .x-inner-el {
    background-color: #f5f5f5  !important;
    border: 1px solid #e9e9e9   !important;
    background-image: none !important;
}
.guidaButton .x-font-icon {
    color: #157FCC !important
}

.guidaButton .x-text-el {
    color: #157FCC !important
}

.doc-grid{
    width: 100vw;
    height: 95vh;
}
.doc-grid .x-mask-inner{
    width: 95vh;

}
.doc-panel {
    min-width: 230px;
    width: 95%;
    margin: 5px auto;
}
.excel-panel > .x-panelheader  {
    background-color: #0ba200 !important;
    border: 1px solid #0ba200 !important;
}
.noDocContainer{
    display: flex;
    align-items: center;
    height: 100%;
}
.iframeDoc{
    width: 100%;
    height: 81vh;
    border: none;
}
.dashboard-header {
    background-color: #157fcc;
}

.dashboard-header .x-panel-body-el {
    background-color: #157fcc;
    color: #fff;
    font-size: 30px;
}
.dashboard-header div {
    display: flex;
}

.dashboard-header span {
    margin-left: 10px;
    padding-top: 10px;
}

.dashboard-button {
    color: #fff;
    padding: 10px;
}

canvas{
    margin: auto;
}

.containerCreaRtoDaPO {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

    .containerCreaRtoDaPO .x-body-el:not(.pippo, .pippo *) {
        display: flex;
        flex-direction: column;
    }

.altezzaComIF {
    max-height: 320px;
    overflow-y: auto;
}

.altezzaNews {
    max-height: 320px;
    overflow-y: auto;
    margin-top: 25px;
    margin-left: 25px;
}
.x-toast {
    position: fixed !important;
    transform: none !important;
    bottom: 30px !important;
    right: 20px !important;
}

.x-toast .x-toast-body-el {
    padding: 15px !important;
    background: #0ba200 !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 14px !important;
}

.chart-container {
    margin: 0 25%;    
}

@media screen and (max-width: 1200px) {
    .chart-container {
        margin: 0%;
    }
}

.x-component-roweditor-buttons{
    background-color: yellow !important
}
.x-component-roweditor-buttons .x-inner-el{
    background-image: none !important;
    border: none !important
}
.corsivoNoGrassetto > .x-panelheader >.x-panelheader-body-el> .x-paneltitle > .x-body-el, .corsivoNoGrassetto > .x-panelheader >.x-panelheader-body-el> .x-paneltitle > .x-body-el >.x-text-el{
width: 100%;
height: 100%;
}

.tableIconTooltipRTO .x-icon-el{
    width: 20px !important;
    height: 20px !important;
}
.tableIconTooltipRTO24 .x-icon-el {
    width: 24px !important;
    height: 24px !important;
}

.nuvola .x-icon-el {
    width: 20px !important;
}

.appButtonPO > .x-inner-el {
    background-color: #A00000 !important;
    border-color: #A00000 !important;
    background-image: none !important;
    height: 24px !important;
}

.appButtonPO .x-font-icon {
    color: #FFFFFF !important
}

.appButtonPO .x-text-el {
    color: #FFFFFF !important
}

.appButtonRTO > .x-inner-el {
    background-color: #157FCC !important;
    border-color: #157FCC !important;
    background-image: none !important;
    height: 24px !important;
}

.appButtonRTO .x-font-icon {
    color: #FFFFFF !important
}

.appButtonRTO .x-text-el {
    color: #FFFFFF !important
}

.chartParent .x-layout-fit{
    justify-content: center;
}
.userDiv> .x-body-el{
    display: flex;
    align-items: center;
}
.gridNotifiche> .x-body-el{
    margin-bottom: 10px;
    display: flex;
    align-items:center;
}


.containerNotificheHome > .x-panelheader {
    margin-bottom: 25px;
}

/*colori custom per ListRTO*/
.greenBackground:not(.x-selected):not(.x-overed) {
    background-color: #bbeebb !important;
}
.yellowBackground:not(.x-selected):not(.x-overed) {
    background-color: #FEF3BE !important;
}
.whiteBackground:not(.x-selected):not(.x-overed) {
    background-color: #FFFFFF !important;
}
.beigeBackground:not(.x-selected):not(.x-overed) {
    background-color: #f3e8c0 !important;
}

.icongridForza {
    color: #47b649 !important;
    font-size: 20px !important;
    width: 100%;
}

.rtoImg > .x-body-el  {
    display: flex;
    width: max-content;
    margin: auto;
}
.rtoImg  {
    overflow-x: auto;
}
.vesioniPanel{
    min-width: 42px;
}
.infoProcesso>.x-body-wrap-el{
    justify-content: center;
    overflow-x: auto;
}
.infoProcesso>.x-body-wrap-el>.x-panel-body-el{
    width: max-content;
}

.disableErrorTimeField > .x-body-wrap-el > .x-error-el{
    display: none !important;
}

.icon-with-margin::before {
    margin-right: 5px;
}
.selectedCount{
    display: flex;
    align-items: center;
    margin-right: 5px;
}
.selectedCount>.x-innerhtml{
    background-color: #157fcc;
    color: white;
    font-weight: bold;
    min-width: 20px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#ext-boundlist-1 {
    z-index: 10 !important;
}

.custom-grid .x-column-header-inner .x-column-header-text {
    white-space: normal !important;
}
.custom-gridRTO .x-body-el .x-gridcell-body-el {
    white-space: normal; /* changed from nowrap */
    word-break: break-word; /* Optional, per spezzare parole lunghe */
}

.custom-grid .x-gridcolumn .x-text-el {
    white-space: normal !important;
}
.x-start >  .x-column-options-leaf
{
    display: none !important;
}

.configuratorGrid .x-column-options-groupindicator:before {
    content: '\f5fd' !important;
}
.configuratorGrid .x-column-options-visibleindicator:before {
    content: '\f0c8' !important;
}
.configuratorGrid .x-titlebar.x-container.x-component.x-navigation-bar {
    display: none !important;
}

.cdzPassaggiStato{
    text-align: center !important;
    justify-content: center !important;
   /* width: calc(100vw - 55px) !important;*/
    display: flex !important;
}
.cdzPassaggiStato .x-body-el.x-container-body-el.x-component-body-el.x-layout-box.x-layout-hbox.x-horizontal.x-align-stretch.x-pack-start {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}

.hidden {
    display: none;
}
.configuratorGrid .x-column-options-hidden .x-column-options-visibleindicator.x-icon-el {
    color: #e4e4e4 !important;
}


.denied-div {
    margin-right: 10px;
}

.text-denied {
    color: white;
}

.fileFieldButton .x-inner-el {
    background-color: #157FCC !important;
    border-color: #157FCC !important;
    background-image: none !important;
}
.fileFieldButton .x-font-icon {
    color: #FFFFFF !important
}

.fileFieldButton .x-text-el {
    color: #FFFFFF !important
}

.x-messagebox-body-wrap-el .x-messagebox-outer-border-rbl > .x-toolbar-body-el .x-inner-el {
    background-color: #157FCC !important;
    border-color: #157FCC !important;
    background-image: none !important;
}

.x-messagebox-body-wrap-el .x-messagebox-outer-border-rbl > .x-toolbar-body-el .x-font-icon {
    color: #FFFFFF !important
}

.x-messagebox-body-wrap-el .x-messagebox-outer-border-rbl > .x-toolbar-body-el .x-text-el {
    color: #FFFFFF !important
}
.alignItemsInSelect {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    justify-items: center;
  }
.commentsRow .x-listitem.x-gridrow.x-component.x-mark-dirty.x-layout-auto-item{
    position: relative !important;
    height: min-content !important;
    transform: none !important;
}
.commentsRow .x-listitem.x-gridrow.x-component.x-mark-dirty.x-layout-auto-item .x-body-el.x-gridcell-body-el{
    white-space: normal !important;
    word-wrap: break-word !important;
}

.wrap-text .x-grid-cell-inner {
    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
