/* Stili per la tabella */
.weekdays-table {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    grid-auto-flow: row;
    gap: 4px; /* Spazio tra le celle */
    width: min-content;
  }
  
  /* Stili per le celle */
  .weekday-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid #C2C2C2;
    border-radius: 3px;
    background-color: #C2C2C2;
    color: white;
    font-size: 11px;
    font-weight: bold;
    box-sizing: border-box;
  }
  .empty-day{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: transparent;
    font-size: 11px;
    font-weight: bold;
    box-sizing: border-box;
    background-color: none !important;
  }
  .giorni{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: none;
    font-size: 11px;
    font-weight: bold;
    box-sizing: border-box;
  }
  .svg{
    width: 20x;
    height: 20px;
  }
  .buttonAction{
    border: transparent;
    background-color: transparent;
    transition: transform 0.3s ease;
  }
  
  .plus-minus{
    width: 14px;
    height: 16px;
  }